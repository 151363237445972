.offtaker__contract__details__wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 65vh;
}
.offtaker-contract-tabs{
    margin-left: 10px;
    height: 100%;
    width: 100%;
}
.contract__module__title {
    font-size: 10px;
}

.backbutton {
    margin-top: 20px;
    margin-left: 10px;
}

.backbutton button{
    display: inline-flex;
    height: 36px;
    padding: 7px 30px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    cursor: pointer;
}