.generator__edit__contract__container {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    position: relative;
}

.confirmation__modal__placeholder_offtaker {
    width: 80%;
    height: 100%;
    z-index: 999999;
    display: flex;
    flex-grow: 1;
}

.client__details {
    display: flex;
    flex-direction: column;
}

.client__name, .contract__param__title, .contract__update__title {
    font-weight: bold;
}

.contract__description {
    border: 0.5px solid silver;
    max-width: 98%;
    padding-left: 10px;
    padding-right: 10px;
}
.contract__terms__title {
    font-weight: 600;
    color: #1A1A24;
}

.contract__param__details {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 16.666%; 
    width: 100%; 
    padding-top: 3vh;
}

.contract__param__cards {
    display: flex;
    flex-direction: row;    
    height: 100%;
}

.contract__param__card {
   width: 250px;
   margin-right: 20px;
   flex: 1;
   display: flex;
  flex-direction: column;
  height: 100%;
}

.param__name, .param__value  {
    height: 24px;
    border: 1px;
    color: white;
    padding-left: 5px;
    font-size: small;
    margin-bottom: 0;
    padding-bottom: 0;
    margin-top: 0;
    padding-top: 8px;
    
}

.param__name {
    background: #3A3A4A;
}

.param__value {
   
    background: rgb(55, 55, 55);
    margin-bottom: 10px;
}

.section__divider {
    height: 2px;
    background-color: yellow;
    border: none;
}

.contract__update__detail__section {
    display: flex;
    flex-direction: column;
}

.offtaker__contract__edit__form {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.generator__contract__form__group {
    position: relative;
}

.form_controls{
    padding: 1vh;
    gap: 10px;
    display: flex;
    justify-content: center;
}

.key_input__contract__input {
    width: 95%;
    height: var(--textbox-height);
    border: none;
    background: #F6F6FA;
    border-bottom: 0.5px solid black;
    margin: 1vh;
}

.key_input__contract__input::placeholder {
    color: black;
  }

.generator__contract__form__group {
    top:-2px
}

.generator__contract__form__group .react-calendar {
    position: absolute;
    top: -260px;
    right: 30px
}

.react-calendar__tile--active {
    background: black !important;
}

.contract__update__button {
    font-weight: bold;
    width: 100%;
    height: var(--button-height);
    margin-top: 6rem;
    margin-bottom: 0;
    margin-right: 1vh;
    cursor: pointer;
    color: var(--primary-grey);
    border: 1px solid var(--primary-grey);
    background: var(--primary-yellow);
}
.contract__update__button:disabled {
    color: var(--disabled-color);
    background-color: var(--disabled-background-color);
    cursor:not-allowed;
}
.contract__cancel__button {
    font-weight: bold;
    width: 100%;
    height:  var(--button-height);;
    margin-top: 6rem;
    margin-bottom: 0;
    margin-right: 1vh;
    cursor: pointer;
    background-color: var(--primary-black);
    color: var(--primary-white);
    border: 1px solid var(--primary-grey);
}
.note__on__params {
    margin-top: 0;
    color: #2E2E38;
    font-size: small;
}

.contract__termination__option {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background:#2E2E38;
    align-items: center;
}
.keyInputTile{
    border: 1px solid #e1e1e6;;
    width: 30%;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}
.termination__notes {
    color: white;
    font-size: small;
    margin-left: 15px;
}
.comments_text_feild{
    height: 20vh;
    vertical-align: top;
    resize: none;

}
.update_header{
    border-bottom: 1px solid #e1e1e6;
    padding: 1vh;
}

.comments_header{
    margin-left: 1vh;
}

.calendar-container{
    position: relative; 
    display: inline-block;
}

.cal__icon_simple {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 5px;
  }


.contract__termination__button {
    margin-right: 20px;
    height: 24px;
    border: none;
    outline: none;
    background: yellow;
    color: black;
    font-weight: bold;
}

.text_error {
    margin: 0;
    padding: 0;
    color: var(--error-red);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: EYInterstate;
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    padding-left: 5px;
}

