.toast-list {
    position: fixed;
    padding: var(--toast-list-padding);
    width: 100%;
    max-width: var(--toast-list-width);
    max-height: 100vh;
    overflow: hidden auto;
  }

  .toast-list--top-left {
    top: 0;
    left: 0;
  }
  .toast-list--top-right {
    top: 0;
    right: 0;
  }
  .toast-list--bottom-left {
    bottom: 0;
    left: 0;
  }
  .toast-list--bottom-right {
    bottom: 0;
    right: 0;
  }

  @keyframes toast-in-right {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  @keyframes toast-in-left {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  .toast-list--top-left .toast,
  .toast-list--bottom-left .toast {
    animation: toast-in-left var(--toast-speed);
  }
  
  .toast-list--top-right .toast,
  .toast-list--bottom-right .toast {
    animation: toast-in-right var(--toast-speed);
  }
  
  .toast-list .toast {
    transition: transform var(--toast-speed), opacity var(--toast-speed),
      box-shadow var(--toast-speed) ease-in-out;
  }