.generator__edit__contract__container {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    position: relative;
}

.confirmation__modal__placeholder {
    width: 95%;
    height: 95%;
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.client__details {
    display: flex;
    flex-direction: column;
}

.client__name, .contract__param__title, .contract__update__title {
    font-weight: bold;
}

.client__business__justification {
    border: 1px solid grey;
    width: 98%;
}

.contract__description {
    border: 0.5px solid silver;
    max-width: 98%;
    padding-left: 10px;
    padding-right: 10px;
}
.contract__terms__title {
    font-weight: 600;
    color: #1A1A24;
}

.contract__param__details {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 16.666%; 
    width: 60%; 
    padding-top: 3vh;
}

.contract_title_header{
    padding-bottom: 3vh;
    padding-top: 1vh;
}

.contract__param__cards {
    display: flex;
    flex-direction: row;    
    height: 100%;
}

.contract__param__card {
   width: 250px;
   margin-right: 20px;
   flex: 1;
   display: flex;
  flex-direction: column;
  height: 100%;
}

.param__name, .param__value  {
    height: 24px;
    border: 1px;
    color: white;
    padding-left: 5px;
    font-size: small;
    margin-bottom: 0;
    padding-bottom: 0;
    margin-top: 0;
    padding-top: 8px;
    
}

.param__name {
    background: var(--alternate-grey);
}

.param__value {
   
    background: rgb(55, 55, 55);
    margin-bottom: 10px;
}

.section__divider {
    height: 2px;
    background-color: var(--primary-yellow);
    border: none;
}

.contract__update__detail__section {
    display: flex;
    flex-direction: column;
}

.generator__contract__edit__form {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-bottom: 10px;
}

.generator__contract__form__group {
    position: relative;
    padding-bottom: 2vh;
}

.generator__contract__input {
    width: 240px;
    height: 34px;
    border: none;
    background: #F6F6FA;
    border-bottom: 0.5px solid black;
    color: black;
}

.generator__contract__input::placeholder {
    color: black;
}

.generator__contract__form__group .cal__icon {
    top:-2px
}

.generator__contract__form__group .react-calendar {
    position: absolute;
    top: -260px;
    right: 30px
}

.react-calendar__tile--active {
    background: black !important;
}

.contract__termination__offtaker__terms{
    border: 1px solid #e1e1e6;
    margin-left: 5px;
}

.contract__upate__button {
    border: 1px solid black;
    background: var(--primary-yellow);
    font-weight: bold;
    height: 35px;
    width: 160px;
    margin-bottom: 0;
}
.termination_fee_feild{
    border: 1px solid grey;
    height: 4vh;
    margin-top: 0;
}
.note__on__params {
    margin-top: 0;
    color:  var(--primary-grey);
    font-size: small;
}

.contract__termination__option {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: var(--primary-grey);
    align-items: center;
}
.termination__notes {
    color: white;
    font-size: small;
    margin-left: 15px;
}

.contract__termination__button {
    margin-right: 20px;
    height: 24px;
    border: none;
    outline: none;
    background: var(--primary-yellow);
    color: black;
    font-weight: bold;
}

