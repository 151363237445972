.modal__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0, 0.7);
    z-index: 9999999;
}
.contract__edit__contract-simple__modal__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-60%, -56%);
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 10px 20px;
    z-index: 99999999999;
    height: 306px;
}
.contract-simple__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid grey;
    margin-bottom: 5px;
}
.contract-simple__title {
    font-weight: bold;
    font-size: 12px;
}
.close__contract-simple__modal {
    font-size: 15px;
    padding: 10px;
    cursor: pointer;
}
.contract-simple__params__cards {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding-top: 50px;
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid grey;
}
.contract-simple__params__card {
    width: 220px;
    height: 80px;
    margin-bottom: 0px;
    margin-top: 0px;
    border: 1px solid grey;
}
.contract-simple__param {
    background: #E1E1E6;
    margin-top: 0;
    margin-bottom: 0px;
    padding-left: 5px;
}
.contract-simple__value {
    background: #FAFAFC;
    margin-top: 0;
    padding-left: 5px;
    height: 79%;
}
.contract-simple__option__button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 20px;
    margin: 0 10px;
}
.contract-simple__option__button button {
    width: 80px;
    height: 35px;
    font-weight: bold;
    outline: none;
    cursor: pointer;
}
.contract-simple__confirm {
    border: none;
    background: black;
    color: white;
}
.contract-simple__cancel {
    border: 1px solid black;
    background: white;
    color: black;
}