.success__modal__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-45%, -56%);
    display: flex;
    flex-direction: column;
    background-color: #fff;
    z-index: 99999999999;
    width: 400px;
    height: auto;
}

.success__modal__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid grey;
    margin-bottom: 5px;
    padding: 0 20px;
}

.success__modal__header__title{
    display: flex;
    align-items: center;
    font-family: EYInterstate;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.success__modal__header__icon{
    height: 16px;
    width: 16px;
    margin-left: 5px;
}

.close-modal{
    cursor: pointer;
}

.success__modal__header .success__info {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.success__modal__content__message {
    display: flex;
    border-bottom: 1px solid grey;
    font-family: EYInterstate;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    height: 7rem;
    padding-left: 20px;
}

.success__modal__content p{
    margin: 0px;
}

.success-modal-footer {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.success-modal-footer button {
    border: none;
    background: black;
    color: white;
    font-weight: bold;
    font-size: 12px;
    height: var(--button-height);
    padding: 11px 36px;
    margin-left: 5px;
    cursor: pointer;
}

