.banner__container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.banner-search-container {
    margin-left: 3rem;
    margin-bottom: 2rem
}

.banner-search-bar-icon {
    position: relative;
    right: 27px;
    top: 9px;
    fill: white;
}

.banner-search-bar {
    width: 450px;
    height: 40px;
    border: none;
    border-bottom: 0.75px solid var(--primary-yellow);
    background-color: var(--light-grey);

    color: var(--primary-white);
    font-family: EYInterstate;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
    padding-left: 0.75rem;
}

input:focus {
    outline: none;
}

input::placeholder {
    color: var(--primary-white);
}