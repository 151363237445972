.user__info__container {
    width: 17%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    z-index: 99999;
    position: absolute;
    color: white;
    right:0;
    background-color: black;
    padding: 10px;
}

.user__info__container p{
    overflow-wrap: break-word;
}

.user__info__avatar__name,.user__info__email{
    max-width: 95%;
}

.user__info__avatar__name .user__avatar {
    display: flex;
    border: none;
    background: grey;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    color:white;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 5px;
}

.user__avatar span{
    font-size: 12px;
    font-family: EYInterstate;
    font-weight: 400;
}

.user__info__avatar__name, .user__info__signout__opt {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: 5px;
    cursor: pointer;
}

.user__info__signout__opt p {
    color: var(--primary-yellow);
}

.user__info__avatar__name .user__full__name {
    font-weight: 700;
}
