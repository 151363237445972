.upload-data-form-group label {
    color: var(--primary-grey);
    font-size: 14px;
    font-weight: 700;
    font-family: EYInterstate;
}

.upload-data-input {
    border: none;
    background-color: var(--placeholder-background);
    border-bottom: 1px solid black;
    height: 30px;
    padding: 8px;
}

.invoice-close-icon{
    cursor: pointer;
}

.upload-data-input::placeholder {
    color: black !important;
}

.admin-upload-data-container {
    width: 70%;
    margin-left: 2rem;
}

.admin-upload-data-active-data-container{
    width: 70%;
}

.active-data-container {
    display: flex;
    flex-direction: column;
    height: 45px;
    border: 1px solid #E7E7EA;
    background: #FAFAFC;
    margin-top: 2rem;
}

.data-meta-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 1rem;
    margin-top: 0.75rem
}

.data-meta-container .data-path {
    display: flex;
    align-items: center;
    color: #000;
    font-family: EYInterstate;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin: 0;
}

progress {
    height: 4px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
}
progress::-webkit-progress-bar {
    background-color:#E7E7EA;
}
progress::-webkit-progress-value {
    background-color: var(--primary-grey);
}
progress::-moz-progress-bar {
    background-color: #E7E7EA;
}

progress::-moz-progress-value {
    background-color: var(--primary-grey);
}

.upload-error-container {
    display: flex;
    margin-top: 1rem;
    color: var(--specific-use-colors-error-b-9251-c, #B9251C);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: EYInterstate;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
}


.file-upload-actions {
    width: 45%;
    display: flex;
    gap: 10px;
    margin-top: 4rem;
    margin-left: 20px;
    margin-bottom: 1rem;
}

.file-upload-actions button {
    cursor: pointer;
    display: inline-flex;
    height: 44px;
    padding: 11px 36px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    font-family: EYInterstate;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    border: none;
}

.file-upload-actions .confirm-button {
    color: var(--primary-grey);
    border: 1px solid var(--primary-grey);
    background:  var(--primary-yellow);
}

.file-upload-actions .confirm-button:disabled{
    color: var(--disabled-color);
    background-color: var(--disabled-background-color);
    cursor: not-allowed;
}

.file-upload-actions .cancel-button {
    background-color: var(--primary-black);
    color: var(--primary-white);
}