.generator__edit__contract__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: 10px;
    position: relative;
}

.confirmation_modal_outer_container {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999999;
    display: flex;
    flex-grow: 1;
}

span.client__name{
    font-family: EYInterstate;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
}

span.client__name__value {
    font-family: EYInterstate;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
}
/* 
.client__name, .contract__param__title, .contract__update__title {
    font-weight: bold;
} */

.contract__description {
    border: 0.5px solid silver;
    max-width: 98%;
    padding-left: 10px;
    padding-right: 10px;
}

.contract__description .contract__terms__title{
    font-family: EYInterstate;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #1A1A24;
}

.contract__description .contract__terms__desc {
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: EYInterstate;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 19.2px; /* 160% */
    letter-spacing: -0.2px;
}


.contract__param__details {
    display: flex;
    flex-direction: column;
}

.contract__param__details .contract__param__cards .contract__param__card .param__name {
    color: #FFF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: EYInterstate;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; 
}

.contract__param__details .contract__param__title {
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: EYInterstate;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; 
}

.contract__param__cards {
    display: flex;
    flex-direction: row;    
    gap: 10px;
    width: 99%;
}

.contract__param__card {
    width: 282.937px;
    height: 102px;
    flex-shrink: 0;
    background: #3A3A4A;
}

.contract__param__details .contract__param__cards .contract__param__card .param__value  {
    height: 24px;
    border: 1px;
    color: var(--theme-light-grey-100-light-theme-f-6-f-6-fa, #F6F6FA);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: EYInterstate;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
}



.param__value {
    margin-bottom: 10px;
}

.section__divider {
    height: 1px;
    width: 105%;
    margin-top: 5px;
    background-color: var(--primary-yellow);
    border: none;
}

.contract__update__detail__section {
    display: flex;
    flex-direction: column;
}

.contract__update__detail__section .contract__update__title {
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: EYInterstate;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
}

.generator__contract__edit__form {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-bottom: 10px;
    height:80px;
}

.generator__contract__edit__form .form__group {
    width: 250px;
}


.generator__contract__edit__form .form__group .options__icons {
    right: 0;
    top: -5px;
}

.generator__contract__edit__form .form__group .admin__contract__input {
    background: #f6f6fa;
}

.generator__contract__form__group {
    position: relative;
}

.editContract-generator__contract__input {
    width: 221px;
    border: none;
    background: #F6F6FA;
    border-bottom: 0.5px solid black;
    color: black;
    position: relative;
    height:var(--textbox-height);
}

.editContract-generator__contract__input .admin__contract__input {
    background: #F6F6FA;
    border: none;
}
.editContract-generator__contract__input .options__icons {
    top: -6px;
}

.editContract-generator__contract__input::placeholder {
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: EYInterstate;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px; 
}
.editContract-generator__contract__input:disabled {
    cursor: not-allowed;
}

.editContract_text_error{
    color: var(--error-red);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: EYInterstate;
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    padding-left: 5px;
}

/* .generator__contract__form__group .cal__icon {
    top:-2px
}

.generator__contract__form__group .react-calendar {
    position: absolute;
    top: -260px;
    right: 30px
} */

/* .react-calendar__tile--active {
    background: black !important;
} */

.edit__contract__upate__button {
    border: 1px solid black;
    background: var(--primary-yellow);
    font-weight: bold;
    font-size: 12px;
    height: var(--button-height);
    padding: 11px 36px;
    color: var(--light-grey);
    font-family: EYInterstate;
    font-style: normal;
    line-height: 22px;
    cursor: pointer;
    margin-bottom: 0;
}

.edit__contract__upate__button:disabled, .editContract_contract__termination__button:disabled {
    background: var(--disabled-background-color);
    font-weight: bold;
    font-size: 12px;
    height: var(--button-height);
    padding: 11px 36px;
    color:  var(--disabled-color);
    font-family: EYInterstate;
    font-style: normal;
    line-height: 22px; 
    cursor: not-allowed;
    margin-bottom: 0;
}

.editContract_contract__termination__button:disabled {
    width: auto;
}

.edit_contract_note__on__params {
    margin-top: 10px;
    color: #2E2E38;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: EYInterstate;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px; 
}

.contract__termination__option {
    display: flex;
    width: 99%;
    height: 66px;
    justify-content: center;
    align-items: center;
    background:var(--light-grey);
}
.termination__notes {
    color: white;
    font-size: small;
    margin-left: 15px;
}

.editContract_contract__termination__button {
    margin-right: 20px;
    font-weight: bold;
    font-size: 12px;
    height: var(--button-height);
    padding: 11px 36px;
    border: none;
    outline: none;
    background: var(--primary-yellow);
    color:#2E2E38;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: EYInterstate;
    font-style: normal;
    line-height: 22px;
    cursor: pointer;
}

