.admin__modify__contract__modal__container {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-45%, -56%);
    display: flex;
    flex-direction: column;
    background-color: #fff;
    z-index: 99999999999;
    width: 1000px;
    height: 530px;
}

.admin__modify__contract__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid grey;
    padding: 0 20px;
}



.admin__modify__contract__title {
    font-family: EYInterstate;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
}

.admin__modify__contract__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid grey;
}

.admin__modify__contract_col_1 {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-left: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    gap:20px;
}

.admin__modify__contract_col_1 .admin__contract__modify__form__group input{
    width: 445px;
    height: 44px;
    background: #f6f6fa;
}

.admin__modify__contract_col_1 .admin__contract__modify__form__group{
    margin-left: 10px;
}

.admin__modify__contract_col_2 .admin__contract__modify__form__group {
    gap: 10px;
    margin-bottom: 20px;
}

.admin__contract__modify__form__group {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    gap:10px;
}

.admin__contract__modify__form__group label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.close-modal{
    cursor: pointer;
}

.admin__contract__modify__form__group .admin__contract__modify__input {
    width: 500px;
    border: none;
    border-bottom: 1px solid black;
    background: #f3f3f3;
}
.admin__contract__modify__form__group .admin__contract__modify__input::placeholder {
    color: black;
    white-space: nowrap;
    font-family: EYInterstate;
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
}

.admin__contract__modify__input_col_2::placeholder {
    font-family: EYInterstate;
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
}

.admin__contract__modify__input_col_2:disabled,  .admin__contract__modify__input:disabled{
    cursor: not-allowed;
}


.custom__input {
    bottom: 1px solid #ccc;
    border: none;
    border-bottom: 1px solid grey;
    width: 445px;
    min-height: 190px;
    word-break: break-word;
    white-space: pre-wrap;
    background: #F6F6FA;
    font-family: EYInterstate;
    font-size: 16px;
    font-weight: 300;
    line-height: 19px;
    letter-spacing: -0.20000000298023224px;
    text-align: left;
}

#edit_contract_desc {
    height: 50px;
}

.admin__modify__contract_col_2 {
    border-left: 1px solid grey;
    padding-left: 50px;
    margin-right: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.admin__modify__contract_col_2 input {
    width: 356px;
    height: 44px;
    border: none;
    border-bottom: 1px solid black;
    background: #F6F6FA;
    
}

.admin__modify__contract_col_2 input::placeholder {
    color: black;
}

.contract__edit__label{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 500px;
    color: #2E2E38;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: EYInterstate;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
}

.admin__contract__modify__option__button {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-left: 10px;
    margin-bottom: 20px;
}

.admin__contract__modify__option__button .option__button__forward {
    border: none;
    background: black;
    color: white;
    font-weight: bold;
    font-size: 12px;
    height: var(--button-height);
    padding: 11px 36px;
}

.admin__contract__modify__option__button .option__button__forward:disabled{
    color: var(--disabled-color);
    background-color: var(--disabled-background-color);
    cursor: not-allowed;
    border: none;
    font-weight: bold;
    font-size: 12px;
    height: var(--button-height);
    padding: 11px 36px;
}

.admin__contract__modify__option__button .option__button__cancel {
    border: 1px solid black;
    background: white;
    font-weight: bold;
    cursor: pointer;
    font-weight: bold;
    font-size: 12px;
    height: var(--button-height);
    padding: 11px 36px;
}