.metrics__card {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 292px;
    min-height: 105px;
    background-color: transparent;
    margin-bottom: 10px;
    color: white;
}

.metrics__card.grey {
    color: black;
    background:white;
}

.metric__name {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #747480;
    height: 45px;
}

.metric__name .icon_img {
    width: 16px;
    height: 16px;
}

.metric__value {
    border: 1px solid #747480;
}

.metric__name, .metric__value {
    padding: 5px 10px
}
