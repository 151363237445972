.file-upload-container {
  display: flex;
  flex-direction: column;
}

.file-upload {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px dashed #c4c4cd;
  background: #f6f6fa;
  color: #000;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.file-upload-placeholder-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.file-upload-icon {
  display: flex;
  width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.file-upload-placeholder {
  color: #000;
  font-family: EYInterstate;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
}

.file-upload-placeholder .text-highlight {
  color: #155cb4;
  font-family: EYInterstate;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}

.file-upload-container .info-container {
  font-family: EYInterstate;
  font-weight: 300;
  font-size: 10px;
  color: #2e2e38;
  margin-top: 14px;
  margin-left: 0;
}
