.modal__wrapper{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.edit__confirmation__modal__container_final {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    z-index: 99999999999;
    width: 30%;
    /* height: 306px; */
}

.edit__confirmation__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid grey;
    margin-bottom: 5px;
    padding: 0 20px;
}

.edit__confirmation__message {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid grey;
    margin-bottom: 5px;
    padding: 0 20px;
}

.edit__confirmation__message p{
    font-size: 14px;
    font-weight: 300;
}

.confirmation__title {
    font-weight: 400;
    font-size: 16px;
}
.modal__close {
    cursor: pointer;
}

.edit__confirmation__button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 20px;
    margin: 0 10px;
    padding: 10px 20px;
}

.edit__confirmation__button button {
    font-weight: bold;
    font-size: 12px;
    height: var(--button-height);
    padding: 11px 36px;
    cursor: pointer;
}

.confirmation__confirm {
    border: none;
    background: black;
    color: white;
}

.confirmation__cancel {
    border: 1px solid black;
    background: white;
    color: black;
}