.view-invoice-icon.disabled {
    cursor: not-allowed;
}
.user-request-title{
    height: 40px;
    background: #FFE600;
    color: #1A1A24;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 15px;
}

.user-request-table-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    border: 1px solid #E1E1E6;
    padding: 10px 20px;
}
