.admin-permission_wrapper {
    margin-top: 20px;
}

.admin-permission-title {
    height: 40px;
    background: #FFE600;
    color: #1A1A24;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 20px;
    margin-right: 20px;
    padding-right: 26px;
    padding-left: 15px;
}

.admin-permission-form-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-left: 20px;
    margin-right: 20px;
    border: 1px solid #E1E1E6;
    padding: 10px 20px;
}

.admin-permission-actions {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 20px;
}

.admin-permission-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.admin-permission-form-group {
    margin-top: 2rem;
    width: 45%;
    height:95px;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 10px;
}

.admin-permission-form-group label {
    color: var(--light-grey);
    font-size: 14px;
    font-weight: 700;
    font-family: EYInterstate;
}
.cal__icon {
    position: absolute;
    right: 0;
    top:20px;
}

.admin-permission-input {
    border: none;
    background-color: var(--placeholder-background);
    border-bottom: 1px solid black;
    height: 30px;
    padding: 8px;
}

.admin-permission-input:disabled {
    color: var(--disabled-color);
    cursor: not-allowed;
    opacity: 0.7;
}

.admin-permission-select-input {
    border: none;
    height: var(--textbox-height);
    background-color: var(--placeholder-background);
    border-bottom: 1px solid black;
}

.admin-permission-select-input::placeholder{
    color: var(--disabled-color);
}

.admin-permission-select-input:disabled  {
    color: var(--disabled-color);
    cursor: not-allowed;
}

.admin-permission-input::placeholder {
    color: black !important;
}

.admin-permission-input:disabled::placeholder {
    color: var(--disabled-color) !important;
}

.admin-permission-actions button {
    border: 1px solid black;
    font-weight: bold;
    font-size: 12px;
    height: var(--button-height);
    padding: 11px 36px;
    cursor: pointer;
}

.admin-permission-actions .confirm-button {
    background-color: #FFE600;
    color: black;
}

.admin-permission-actions .confirm-button:disabled{
    color: #747480;
    background-color: #EAEAF2;
}

.cancel-button {
    background-color: white;
}

.info-container {
    font-family: EYInterstate;
    font-weight: 300;
    font-size: 10px;
    color: #2E2E38;
    margin-left: 20px;
    margin-top: 14px;
}