.support_container {
    min-height: 70vh;
    display: flex;
    justify-content: space-between;
    gap:10%;
    flex-direction: row;
}

.support_content_left {
    align-items: flex-start;
    width: 50%;
}

.support_content_text{
    margin-left: 10%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10%;
}

.support_content_text  p {
    color: var(--disabled-color);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: EYInterstate;
    font-size: 14px;
    line-height: 22px;
    font-style: normal;
    font-weight: 300;
    padding-left: 5px;
    margin:5px;
}

.support_content_contact_details{
    margin-left: 20%;
    display: flex;
    flex-direction: column;
}

.support_content_contact_details_text{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.support_content_contact_details_text  p {
    color: var(--primary-black);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: EYInterstate;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    padding-left: 5px;
    margin:5px;
}

.support_icon{
    height: 20px;
    width: 20px;
}

.support_content_left  h1 {
    font-family: EYInterstate;
    font-size: 40px;
    margin-bottom:10px;
    padding-left: 10px;
}

.support_content_right {
    display: flex;
    width: 50%;
    flex-direction: column;
    gap:5px;
    margin-right: 10%;
    margin-top: 20px;
}

.admin__client__onboarding__form{
    gap:5px;
}

.support_content_right input {
    display: flex;
    height: 30px;
    width: 98%;
    margin-top: 5px;
}

.support_content_right label{
    margin-bottom: 5px;
}

.support_content_right select {
    display: flex;
    height: 40px;
    width: 100%;
    margin-top: 5px;
}

.support_content_right textarea {
    display: flex;
    width: 100%;
    height: 150px;
    margin-top: 5px;
}

.support_content_right  p {
    color:#FFF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: EYInterstate;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    cursor: pointer;
}

.support_content_right button {
    width: 40%;
    font-weight: bold;
    font-size: 12px;
    height: var(--button-height);
    padding: 11px 36px;
    border: 1px solid black;;
    color:var(--primary-black);
    background: var(--primary-yellow);
    border-radius: 0px;
    margin-top: 10px;
    cursor: pointer;
}

.support_content_right button:disabled {
    width: 40%;
    font-weight: bold;
    font-size: 12px;
    height: var(--button-height);
    padding: 11px 36px;
    border: 1px solid black;
    color: var(--disabled-color);
    background-color: var(--disabled-background-color);
    cursor: not-allowed;
}

.support_content_right_wrapper{
    margin-top: 10px;
}