.modal__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0, 0.7);
    z-index: 99999999;
}

.contract__termination__modal__container {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    overflow: auto;
    z-index: 99999999999;
    width: 60%;
    height: 80%;
    font-family: EYInterstate;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
}

.contract__termination__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid grey;
}

.contract__termination__title {
    font-family: EYInterstate;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
}

.contract__current__param__cards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid grey;
    padding: 20px 20px;
    font-family: EYInterstate;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;

}

.contract__current__param__card {
    width: 48%;
    height: 88px;
    border: 1px solid grey;
}

.contract__param__name {
    width: 100%;
    background-color: #FAFAFC;
    border-bottom: 1px solid grey;
    margin: 0;
    padding: 10px 0px;
}

.contract__param__value {
    width: 100%;
    background-color: #fff;
    margin: 0;
    padding: 10px 0px;
}
.contract__termination__req__params {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    padding: 0 20px;
}

.contract__termination__req__form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 30px;
    margin-bottom: 20px;
}

.contract__termination__req__form .form__group {
    width: 280px;
    height: 47px;
}
.contract__termination__req__form .form__group .admin__contract__input {
    background: #eaeaf2;
    width: 280px;
    height: 47px;
}


.contract__termination__form__group {
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: relative;
    margin-top: 15px;
    width: 48%;
}

.contract__termination__form__group_date{
    display: flex;
    flex-direction: column;
    gap: 5px;
    position: relative;
    width:40%;
}

.contract__termination__req__form .form__group {
    height: 56px;
}

.contract__termination__req__form .form__group .options__icons{
    top: 17px
}

.early__term__date {
    height: 47px;
    flex-shrink: 0;
    margin-top: 15px;
}

.early__term__date .options__icons{
    top:50px
}

.early__term__date input{
    width: 100%;
    margin-top: 15px;
}

.contract__termination__form__group .calendar__container .react-calendar {
    position: absolute;
    right: 5px;
    width: 300px;
    z-index: 99999999999999999;
}
.contract__termination__form__group .cal__icon {
    position: absolute;
    right: -5px;
    top: 12px;
}

.contract__termination__cal {
    border: none;
    outline: none;
    background-color: #F6F6FA;
    border-bottom: 1px solid black;
    height: 24px;
    width: 200px;
}

.contract__termination__cal::placeholder {
    color: black;
}


.contract__termination__input {
    border: 1px solid #E1E1E6;
    outline: none;
    background-color: white;
    height: var(--button-height);
    width: 100%;
    overflow-x: scroll;
    overflow-y: unset;
    resize: none;
}

.contract_termination__button {
    display: flex;
    flex: row;
    gap: 10px;
}

.contract_termination__button .check__fee__button {
    display: flex;
    height: var(--button-height);
    font-family: EYInterstate;
    font-size: 14px;
    font-weight: 400;
    padding: 11px 25px;
    justify-content: center;
    align-items: center;
}

.contract_termination__button .check__fee__button.active {
    background:#2E2E38;
    color: white;
}

.contract_termination__button .select__button {
    display: inline-flex;
    height: 36px;
    padding: 7px 30px;
    justify-content: center;
    align-items: center;
    gap: 2px;
    flex-shrink: 0;
}

.contract_termination__button .select__button.inactive {
    background:  #EAEAF2;
}

.contract_termination__button .select__button.active {
    border: 1px solid  #2E2E38;
    background:  #FFE600;
}

.contract__termination__terms {
    border: 1px solid #E1E1E6;
    margin-left: 18px;
    margin-right: 10px;
    margin-top: 15px;
}

.contract__termination__desc {
    height: 120px;
    overflow-y: scroll;
    padding: 0 20px;
}

.contract__termination__header {
    padding: 4px 20px;
    font-weight: bold;
    font-size: small;
}

.contract__terms__checkbox {
    display: flex;
    flex-direction: row;
    gap: 2px;
    padding-left: 18px;
}

.divider__section {
    height: 1px;
    background: #ecebeb;
    width: 100%;
    margin-bottom: 2px;
}

.contract__termination__confirmation__button {
    display: flex;
    flex-direction: row;
    padding-left: 10px;
    padding: 20px 0 20px 5px;
    gap: 20px;
    margin-left: 15px;
}
.contract__termination__confirmation__button button {
    font-weight: bold;
    font-size: 12px;
    height: var(--button-height);
    padding: 11px 36px;
    gap: 15px;
    border: 1px solid black;
    outline: none;
    cursor: pointer;
}

.contract__termination__confirmation__button .termination__confirmation {
    background: black;
    color: white;
}

.contract__termination__confirmation__button .termination__confirmation:disabled {
    background: var(--disabled-background-color);
    color: var(--disabled-color);
    cursor: not-allowed;
}

.contract__termination__confirmation__button .termination__cancel {
    background: white;
    color: black;
}