.generator__client__overview__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* height: 800px;
    overflow-y: scroll; */
    font-family: EYInterstate;
    font-size: 12px;
    font-weight: 300;
    line-height: 19px;
    letter-spacing: -0.20000000298023224px;
    text-align: left;
}

.generator__client__name {
    color: #FFF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: EYInterstate;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.2px; /* 160% */
    letter-spacing: -0.2px;
}
.generator__client__overview__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    background: black;
    color: white;
    width: 96%;
    font-family: EYInterstate;
    font-size: 12px;
    font-weight: 300;
    line-height: 19px;
    letter-spacing: -0.20000000298023224px;
    text-align: left;

}

.generator___client__field {
    color: #FFE600;
    font-family: EYInterstate;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 19.2px; /* 160% */
    letter-spacing: -0.2px;
}

.generator__client__overview__header button {
    width: 130px;
    height: 24px;
    background: #FFE600;
    color: black;
    font-weight: bold;
    border: none;
    outline: none;
}

.client__business__justification {
    border: 1px solid grey;
    width: 98%;
}

.client__detailed__info {
    margin-top: 20px;

}
.client__detailed__info table {
    border-top: 1px solid grey;
    margin-left: 0px;
    font-family: EYInterstate;
    font-size: 12px;
    font-weight: 300;
    line-height: 19px;
    letter-spacing: -0.20000000298023224px;
    text-align: left;
    width: 100%;

}

.client__detailed__info table tr th {
        margin-top: 10px;
        padding: 10px 10px;
        font-size: 12px;
        line-height: 19px;
        letter-spacing: -0.20000000298023224px;
        text-align: left;
}

.client__detailed__info table tr td {
    padding-left: 10px;
    font-family: EYInterstate;
    font-size: 12px;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
}

.client__detailed__info table tr td.status__info {
    color: #168736;
    font-family: EYInterstate;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;

}

.header_info {
    font-family: EYInterstate;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #1A1A24
}

.client__business__justification {
    margin: 20px 0;
    padding: 0 10px;
    border: 1px solid #E7E7EA;
    width: 97%;
}

.client__business__justification .description {
    font-family: EYInterstate;
    font-size: 12px;
    font-weight: 300;
    line-height: 19px;
    letter-spacing: -0.20000000298023224px;
    text-align: left;
    color: #000;
}

.generator__client__overview__container .section__divider {
    background: #E7E7EA;
    width: 1156px;
    height: 1px;
}

.client__contract__params {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.client__contract__param {
    display: flex;
    flex-direction: column;
    height: 90px;
    justify-content: flex-start;
    width: 300px;
    font-family: EYInterstate;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
}

.client__contract__params__wrapper .client__contract__param__container .client__contract__param__title {
color: #000;
font-family: EYInterstate;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 20px; 
}

.client__contract__param .name {
    background: black;
    color: white;
    padding-left: 10px;
    font-family: EYInterstate;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; 
}

.client__contract__param .value {
    background: black;
    color: white;
    padding-left: 10px;
    font-family: EYInterstate;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
}