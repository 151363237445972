.sidebar__container {
    min-height: 90vh;
    width: 42px;
    z-index: 99;
    background-color: var(--light-grey);
    transition: all .5s ease;
    color: white;
    transition: all 0.3s ease;
    cursor: pointer;
}
.sidebar__container.open {
    width: 257px;
}

.toggle__option {
    cursor: pointer;
    border: none;
    background: var(--light-grey);
    color: white;
    font-size: 20px;
}

.toggle_btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
}

.sidebar__nav__list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin: 0px;
    gap: 5px;
}

.nav__list {
    flex-grow: 1;
    width: 100%;
    list-style: none;
    padding: 0px;
    cursor: pointer;
}
.nav__item_menu__option {
    text-decoration: none;
    color: white;
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 13px 0px 13px 13px;
    transition: all .5s ease;
    color: white;
}

.nav__item_menu__option span{
    color: var(--primary-ey-white-ffffff, #FFF);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: EYInterstate;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
}

.nav__item_sidebar__menu:hover {
    border-left: 3px solid var(--primary-yellow);
    background: var(--primary-black);
}

.nav__item_sidebar {
    display: flex;
    flex-direction: column;
}
.nav__item_sidebar__menu {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.nav__item_sidebar__menu.highlight {
    background: var(--primary-black);
    border-left: 3px solid var(--primary-yellow);
}
.nav__item_sidebar__menu.highlight span{
    color: var(--primary-yellow);
}

.sidebar__submenu {
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.nav__item_sidebar__menu button.submenu__button {
    background: none;
    cursor: pointer;
}

.sidebar__submenu__item {
    font-size: 10px;
    cursor: pointer;
    color: var(--primary-white);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: EYInterstate;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
    padding-left: 50px;
    margin: 10px 0;
}

.sidebar__submenu__item:hover {
    font-weight: bold;
    color: var(--primary-yellow);
}

.sidebar__submenu__item_list {
    height: 100%;
    width: 100%;
}

.submenu_highlight{
    font-weight: bold;
    color: var(--primary-yellow);
    background: var(--sidenav-light-grey);
}

.close {
    display: none;
}

.nav__item_sidebar a{
    background-color: black;
    width: 100%;
    display: flex;
    gap: 5px;
}

.nav__item_sidebar  button {
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    background: var(--light-grey);
    color: white;
}

.nav__item_support {
    margin-top: 30px;
}
