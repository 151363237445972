.ppa__logo__img {
    width: 34px;
    height: 35px;
    object-fit: cover;
}

.header__container {
    /* width: 100%; */
    height: 10vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: var(--light-grey);
    color: white;
}

.header__container .brand__option {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 10px;
}

.collapsible__option {
    width: 24px;
    height: 24px;
    filter: invert(100%);
}

.ppa__brand{
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: row;
    margin-left: 5px;
    margin-bottom: 14px;
    gap: 10px;
    position: relative;
}

.ppa__brand__caption {
    position: absolute;
    top: 0px;
    left: 40px;
    width: 350px;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: EYInterstate;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
}

.user__info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-right: 30px;
    padding-right: 10px;
    gap: 20px;
}

.user__info .user__avatar {
    display: flex;
    border: none;
    background: grey;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    color:white;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.user__notificaiton {
    position: relative;
}

.user__notify {
    position: absolute;
    top: -4.6px;
    left: -6px;
    background: red;
    border-radius: 50%;
    width:13.5px;
    height: 13.5px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: smaller;
}

.notification__count {
    position: absolute;
    top: -14px;
    left: 3px;
}

.user__avatar p {
    font-size: 11px;
}