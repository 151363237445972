.admin__delete__contract__modal__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-45%, -56%);
    display: flex;
    flex-direction: column;
    background-color: #fff;
    z-index: 99999999999;
    width: 800px;
    height: 320px;
}

.admin__delete__contract__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid grey;
    padding: 0 10px;
}

.admin__delete__contact__modal__content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    border-bottom: 1px solid grey;
    margin-bottom: 10px;
}

.admin__delete__contract__params {
    display: flex;
    flex-direction: column;
    border: 1px solid grey;
    width: 245px;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
    margin-top: 20px;
}

.admin__delete__param__name {
    background: rgb(176, 176, 176);
    margin: 0;
    height: 34px;
    border-bottom: 1px solid black;
}

.admin__delete__contract__option__button {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-left: 10px;
}

.admin__delete__contract__option__button  button {
    width: 100px;
    height: 30px;
}
.admin__delete__contract__confirm {
    border: none;
    background: black;
    color: white;
    font-weight: bold;
}

.admin__delete__contract__cancel {
    border: 1px soild black;
    background: white;
    font-weight: bold;
    color: black;
}