.contract__module__title {
    font-size: 10px;
}


/* .redTriangle {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 10px solid red;
    display: inline-block;
    margin-left: 5px;
} */

.contract_id_banner{
    background-color: #3A3A4A;
    padding: 1vh;
}

.banner_title{
    color: #FFE600;
}

.banner_id{
    color: #FFFFFF;
}

.contract_graph_title{
    background-color: #C4C4CD;
    padding: 1.5vh;
}

.contract_tile_legend{
    background-color: #C4C4CD;
    padding: 1vh;
    display: flex;
    justify-content: space-evenly;
}

.legend_item {
    display: flex;
    align-items: center;
  }

  .rectangle {
    display: inline-block;
    width: 26px;
    height: 12px;
    margin-right: 5px;
  }

  .light-blue {
    background-color: #35A4E8;
  }
  
  .dark-blue {
    background-color: #155CB4;
  }

.actions_box{
    padding-top: 3vh;
    padding-right: 1.5vh;
    padding-bottom: 2vh;
}

/* .greenTriangle {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 10px solid green;
    display: inline-block;
    margin-left: 5px;
  } */

.param__name_overview{
    color: black;
    background: #C4C4CD;
}
.param__value_overview{
    color: black;
    background: #EAEAF2;
}

.contract__param__details_overview{
    padding-bottom: 3vh;
}

.param__name_overview, .param__value_overview  {
    height: 24px;
    border: 1px;
    padding-left: 5px;
    font-size: small;
    margin-bottom: 0;
    padding-bottom: 0;
    margin-top: 0;
    padding-top: 8px;
    
}

.offtaker-powerBI_report{
    height: 80%;
    width:100%;
}

.powerBi-report-container{
    height: 100vh;
}