.contract__module__title {
    margin-top: 0;
    margin-left: 10px;
    font-weight: 600;
    font-size: 15px;
}

.tabs__container__box {
    margin-left: 10px;
}

.horizontal_tab_nav {
    position: relative;
}

.horizontal_tab_nav::placeholder {
    color: var(--primary-black);
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
}