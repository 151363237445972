.invoice-list-container {
    margin: 20px;
}

.invoice-count-container {
    display: flex;
    justify-content: space-between;
}

.invoice-count {
    display: flex;
    flex-direction: column;
    width: 48%;
    margin: 20px 0px;
    background: #fff;
    border: 1px solid #E1E1E6;
}

.invoice-count-text-container {
    display: flex;
    justify-content: space-between;
    background-color: var(--alternate-grey);
    padding: 0.65rem;
}

.invoice-count-text {
    color: #FFF;
    font-family: EYInterstate;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.invoice-count-value {
    background-color: #2E2E3C;
    padding: 0.65rem;
}

.invoice-list {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.view-invoice-icon {
    cursor: pointer;
    padding-left: 4px;
    padding-top: 4px;
}

.passed {
    color: #13652A;
    font-weight: 700;
}

.failed {
    color: #B9251C;
    font-weight: 700;
}

.in-progress {
    font-weight: 700;
}