.pagination-container {
    margin-right: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 15px;
}
.pagination-container .button {
    background-color: transparent;
    background-image: none;
    cursor: pointer;
    appearance: button;
    text-transform: none;
    border: none;
    width: 24px;
    margin: 0;
}

.page-size-select, .page-size-select:focus-visible {
    border: none;
    cursor: pointer;
    background-color: transparent;
    background-image: none;
    text-transform: none;
    margin: 0;
}

.page-count {
    padding-top: 3px;
}