.tab__nav__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #898989;
}

.tab__navigation {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  margin: 0 15px;
}
.tab__search {
  margin-top: 10px;
  margin-right: 10px;
  position: relative;
}
.search__icon__nav_menu {
  position: absolute;
  top: 4px;
  left: 325px;
  width: 30px;
  height: 30px;
}
.tab__search input {
  width: 350px;
  height: 30px;
  border: none;
  outline: none;
  background-color: #efefef;
  border-bottom: 2px solid black;
  font-size: 14px;
}
.tab__search input::placeholder {
  color: black;
}

.tab-item {
  cursor: pointer;
  padding: 2px 20px;
  border-bottom: 4px solid transparent;
  transition: all 0.2s ease;
}
.tab-item > p {
  margin: 0 !important;
  padding: 10px 0 5px 0;
}
.tab-item:hover {
  border-color: #666;
}

.tab-item.active {
  border-color: #ffe600;
  background-color: #fff;
  font-weight: normal !important;
}

.search__icon__nav_menu:hover svg path {
  fill: black;
}

.tab-item.readonly {
  pointer-events:none;
}
