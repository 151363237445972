.form__group {
    display: flex;
    flex-direction: column;
    position: relative;
}

.options__icons {
    position: absolute;
    top: 9px;
    right: 0;
    display: flex;
    flex-direction: row;
    gap: 10px;
    cursor: pointer;
}
.admin__contract__input:disabled {
    cursor: not-allowed;
}
.text_error{
    margin: 0;
    padding: 0;
    color: var(--error-red);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: EYInterstate;
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
}