.admin__contract__onboarding__wrapper {
    margin-top: 20px;
}

.admin_contract_greeting {
    margin-left: 20px;
    margin-bottom: 0;
}

.admin__contract__onboarding__form__container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-left: 20px;
    margin-right: 20px;
    border: 1px solid grey;
    padding: 10px 20px;
}

.admin__contract__title {
    height: 40px;
    background: #FFE600;
    color: black;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 20px;
    margin-right: 20px;
    padding-right: 26px;
    padding-left: 15px;
}

.ppa__contract__options {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 20px;
}

.admin__contract__onboarding__form {
    display: flex;
}

.admin_contract_form_column{
    margin: 0px 10px;
    width: calc(33.3% - 10px);
}

.admin__contract__form__group {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 90px;
}

.admin__contract__form__group p {
    font-size: 10px;
    margin-top: 5px;
}

.admin__contract__form__group .options__icons {
    top: 25px;
}

.admin__contract__form__group .close_icon {
    top: 30px;
    position: absolute;
    top: 30px;
    right: 0;
    display: flex;
    flex-direction: row;
    gap: 10px;
    cursor: pointer;
}
.admin__contract__form__group label {
    font-family: EYInterstate;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: var(--primary-grey);
}
.admin__contract__form__group .cal__icon {
    position: absolute;
    right: 0;
    top:23px;
}

.admin__contract__input {
    border: none;
    background-color: var(--placeholder-background);
    border-bottom: 1px solid black;
    padding: 8px;
    height: var(--textbox-height);
    margin-top: 5px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 300;
}

.admin__contract__input__file {
    border: none;
    background-color: var(--placeholder-background);
    border-bottom: 1px solid black;
    padding: 8px;
    height: var(--textbox-height);
    margin-top: 5px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 300;
    padding-right: 30px;
}

.admin__contract__input::placeholder {
    color: black !important;
    font-family: EYInterstate;
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;

}

.ppa__contract__options button {
    border: 1px solid black;
    font-weight: bold;
    font-size: 12px;
    height: var(--button-height);
    padding: 11px 36px;
    cursor: pointer;
}

.forward__option {
    background-color: #FFE600;
    color: black;
}

.cancel__option {
    background-color: white;
}

.text_error{
    margin: 0;
    padding: 0;
    color: var(--error-red);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: EYInterstate;
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
}