.file-upload-container {
    display: flex;
    flex-direction: column;
}

.file-upload-label {
    color: #2E2E38;
    font-size: 18px;
    font-weight: normal;
    font-family: EYInterstate;
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.file-upload {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: 1px dashed #C4C4CD;
    background:  #F6F6FA;
    color: #000;
    outline: none;
    transition: border .24s ease-in-out;
}

.file-upload-placeholder-container {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.file-upload-icon {
    display: flex;
    width: 36px;
    height: 36px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.file-upload-placeholder {
    color: #000;
    font-family: EYInterstate;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
}

.file-upload-placeholder .text-highlight {
    color: #155CB4;
    font-family: EYInterstate;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
}

.file-upload-container .info-container {
    font-family: EYInterstate;
    font-weight: 300;
    font-size: 10px;
    color: #2E2E38;
    margin-top: 14px;
    margin-left:0;
}

.file-upload-form-group{
    display: flex;
    flex-direction: row;
    height: 35px;
    margin-bottom: 40px;
    position: relative;
    width: 50%;
    align-items: center;
    gap: 20px;
}

.file-upload-input{
    background-color: var(--input-light-gray);
    border: none;
    border-bottom: 1px solid #000;
    padding: 10px;
}