
/* .main__layout {
    height: 100vh;
    overflow: hidden;
} */
.main__nav {
    display: flex;
    flex-direction: row;
    overflow: hidden;
}
.main__page {
    flex-grow: 1;
    width: 80%;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
}
.main__page >  :first-child{
    margin-bottom: 10px;
}