.invoice__reconcilication__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 2rem;
}

.button__back__icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3px;
    border: 1px solid #E7E7EA;
    width: 8%;
    height: 95%;
    margin: 10px 0;
}

.client__general__info {
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
}

.client__info__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 10px;
    background: #3A3A4A;
    color: white;
}
.client__name .name span, .invoice__info .name span {
    color: var(--primary-yellow);
}

.client__info__content__container {
    display: flex;
    flex-direction: row;
    background: #2E2E3C;
}

.client__info__card__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: white;
    width: 30%;
}
.client__attribute__header {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.client__attribute__header .client__label {
    color: #FFE600;
}

.client__attribute__value__container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: 1.5rem;
}

.client__info__content__container > :last-child .client__attribute__value__container {
    margin-left: 3.25rem;
}

.client__attribute__lines {
    margin: 0;
}

.invoice__attribute__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0;
    margin: 0 1rem 0 0;
}

.invoice__attribute__card {
    flex: 0 0 20%;
}

.invoice__attribute__card .invoice__attribute__name {
    background: #E1E1E6;
    margin: 0;
    padding: 3px 10px;
}

.invoice__attribute__card .invoice__attribute__value {
    background: #FAFAFC;
    margin: 0;
    padding: 3px 10px;
}

.download__button  {
    margin-top: 5px;
}

.download__button button{
    border: black 1px #2E2E38;
    background: #FFE600;
    color: black;
    width: 180px;
    height: 22px;
    font-weight: bold;
    padding: 0;
}

.section__divider.black {
    background: black;
    height: 15px;
}

.passed {
    color: #13652a;
  }

  .failed {
    color: #B9251C;
  }
  