.remove-user_wrapper {
    padding-left: 25px;
    padding-top: 25px;
    width: calc(100% - 25px);
}

.remove-user-title {
    width: calc(100% - 35px);
    height: 40px;
    background: #FFE600;
    color: #1A1A24;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 20px;
    padding-left: 15px;
}

.remove-user-table-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: calc(100% - 35px);
    border: 1px solid #E1E1E6;
    margin-left: 20px;
    padding-left: 15px;
}

.remove-user-form-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-left: 20px;
    border: 1px solid #E1E1E6;
    padding: 10px 20px;
}


.remove-user-icon {
    cursor: pointer;
}
