.confirm-modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-45%, -46%);
  display: flex;
  flex-direction: column;
  background-color: #fff;
  z-index: 9999999;
  min-width: 30%;
  max-width: 75%;
  max-height: 80%;
  overflow: auto;
  min-height: 15rem;
}

.confirm-modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid grey;
  padding: 0 10px;

  color: var(--primary-grey);
  font-family: EYInterstate;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}

.confirm-modal-header p{
  font-size: 16px;
  font-weight: 400;
}

.confirm-modal-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  border-bottom: 1px solid grey;
  margin-bottom: 10px;
}

.confirm-modal-block {
  display: flex;
  flex-direction: column;
  width: calc(50% - 25px);
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
  margin-top: 20px;
  background: #fff;
  border: 1px solid #e1e1e6;
}

.confirm-modal-block-name {
  border: 1px solid #e1e1e6;
  margin: 0;
  padding: 10px 0px 10px 5px;
  /* border-bottom: 1px solid black; */
  color: #1a1a24;
  font-family: EYInterstate;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  background: #fafafc;
}

.confirm-modal-block-value {
  color: #1a1a24;
  overflow: hidden;
  padding-left: 5px;
  text-overflow: ellipsis;
  font-family: EYInterstate;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px; /* 157.143% */
}

.confirm-modal-actions {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 12px;
}

.confirm-modal-actions button {
  height: 30px;
  cursor: pointer;
}
.confirm-modal-actions .confirm-button {
  border: none;
  background: black;
  color: white;
  font-weight: bold;
  font-size: 12px;
  height: 44px;
  padding: 11px 36px;
}

.confirm-modal-actions .cancel-button {
  border: 1px solid black;
  background: white;
  font-weight: bold;
  font-size: 12px;
  height: 44px;
  padding: 11px 36px;
}

.confirm-modal-header .cursor {
  cursor: pointer;
}

.confirm-policy{
  padding: 0px 20px;
}

.confirm-modal-actions-policy{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 12px;
}

.confirm-modal-actions-policy button {
  height: 30px;
  cursor: pointer;
}
.confirm-modal-actions-policy .confirm-button {
  border: none;
  background: black;
  color: white;
  font-weight: bold;
  font-size: 12px;
  height: 44px;
  padding: 11px 36px;
}
.confirm-policy-modal-header {
  position: sticky;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: flex-end;
  padding: 5px 10px;
  color: var(--primary-grey);
  font-family: EYInterstate;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.confirm-policy-modal-header p{
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}
