.admin__contract__modify__confirmation__modal__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-60%,-57%);
    display: flex;
    flex-direction: column;
    background-color: #fff;
    z-index: 99999999999;
    width: 1000px;
}

.admin__confirmation__modal__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid grey;
    padding: 0 20px;
}

.admin__confirmation__modal__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid grey;
}
.admin__modify__confirmation__left {
    margin-top: 10px;
    margin-left: 30px
}

.admin__modify__confirmation__left, .admin__modify__confirmation__right {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.admin__modify__confirmation__right .metrics__card .metric__name, 
.admin__modify__confirmation__left .metrics__card .metric__name {
    background: #E1E1E6;
}

.admin__modify__confirmation__right .metrics__card .metric__value, 
.admin__modify__confirmation__left .metrics__card .metric__value {
    background: #FAFAFC;
}

.admin__modify__confirmation__left .metrics__card, .admin__modify__confirmation__right .metrics__card {
    width: 350px;
}

 .admin__confirmation__modal__footer__options {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-left: 10px;
    margin-bottom: 20px;
}

.admin__confirmation__modal__footer__options button {
    width: 70px;
    height: 30px;
}

.admin__confirmation__footer__forward {
    border: none;
    background: black;
    color: white;
    font-weight: bold;
}

.admin__confirmation__footer__cancel {
    border: 1px solid black;
    background: white;
    font-weight: bold;
    color: black;
}