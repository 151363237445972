.invoice__reconcilication__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 2rem;
}

.button__back__icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3px;
    border: 1px solid #E7E7EA;
    width: 8%;
    height: 95%;
    margin: 10px 0;
}

.client__general__info {
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
}

.client__info__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 10px;
    background: var(--alternate-grey);
    color: white;
}

.client__info__transactionHash {
    display: flex;
    flex-direction: row;
    gap: 20px;
    background: var(--alternate-grey);
    color: white;
    height: 38px;
    justify-content: center;
    align-items: center;
}
.client__info__transactionHash_heading {
    background: var(--alternate-grey);
    color: white;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: EYInterstate;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.2px;
    width: 15%;
    margin-left: 20px;
}
.client__info__transactionHash_value {
    background: var(--primary-black);
    color: white;
    font-family: EYInterstate;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    width:100%;
    padding-left: 10px;
}

.client__name .name span, .invoice__info .name span {
    color: var(--primary-yellow);
}

.client__info__content__container {
    display: flex;
    flex-direction: row;
    background: #2E2E3C;
    padding-bottom: 10px;
}

.client__info__card__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: white;
    width: 30%;
}
.client__attribute__header {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.client__attribute__header .client__label {
    color: #FFE600;
}

.client__attribute__value__container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: 1.5px;
}

.client__info__content__container > :last-child .client__attribute__value__container {
    margin-left: 3.25rem;
}

.client__attribute__lines {
    margin: 0;
}

.invoice__attribute__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0;
    margin: 0 1rem 0 0;
}

.invoice__attribute__card {
    flex: 0 0 20%;
}

.invoice__attribute__card .invoice__attribute__name {
    background: #E1E1E6;
    margin: 0;
    padding: 3px 10px;
    height: 60px;
}

.invoice__attribute__card .invoice__attribute__value {
    background: #FAFAFC;
    margin: 0;
    padding: 3px 10px;
    height: 60px;
    font-weight: 300;
    font-size: 0.9rem;
}
.invoice_reconcillation_footer{
    display: flex;
    flex-direction: row;
    height: 100px;
    padding-top: 1rem;
    justify-content: space-between;
    margin-top: 1rem;
}

.invoice_reconcillation_footer .invoice_errors  {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    width: 75%;
    gap:10px;
    margin-left: auto;
    align-content: flex-start;
}

.invoice_reconcillation_footer .invoice_errors p {
    font-size: 12px;
    color: red;
    margin: 0px;
    height: 14px;
}

.invoice_error_container{
    display: flex;
    flex-direction: column;
    margin: 0;
    margin-right: 2.5rem;
}

.invoice_error_header{
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
    font-weight: 500;
    color: red;
}

.invoice_reconcillation_footer .download__button{
    display: flex;
    width: 43%;
    gap: 10px;
    margin: 5px;
}

.invoice_reconcillation_footer .download__button button{
    background: var(--primary-yellow);
    border: 1px solid black;
    color: black;
    font-family: EYInterstate;
    font-size: 14px;
    padding: 11px 36px;
    font-weight: 700;
    line-height: 22px;
    cursor: pointer;
    height: var(--button-height);
    width: 100%;
}

.invoice_errors_item{
    height: 15px;
}

.section__divider.black {
    background: black;
    height: 15px;
}

.attribute_error{
    color: red;
}

.attribute_success{
    color: green;
}

.passed{
    color: green;
}

.failed{
    color: red;
}