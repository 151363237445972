.contract__change__request__container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-right: 1vh;
}

table {
    width: 80%;
    /* margin-top: 20px; */
    border-collapse: collapse;
}

.header {
    position: relative;
    display: flex;
    align-items: center;
}
th,td {
    text-align: left;
    border-bottom: 1px solid #ccc;
    font-size: 10px;
    height: 8px;
}


thead th {
    background-color: #f0f0f0;
}

.contract__change__req__footer {
    width: 80%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
}

.pagination__support {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    /* margin-right: 150px; */
    /* margin-right: 270px; */
}

.contract__change__request__options{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    float: right;
    gap: 5px;
    margin-left: 10px;
}

.contract__change__request__options button {
    /* padding: 1px 20px; */
    border: 2px solid black;
    outline: none;
    cursor: pointer;
    padding: 7px 30px 7px 26px;
    transition: all 0.2s ease;
    height: 36px;
}

.contract__change__request__options button  > img,
.contract__change__request__options button  > span {
  vertical-align: middle;
}

/* .contract__change__request__options button:hover{
    border: 2px solid yellow;
    background: black;
    color: white;
} */