.contract__termination__confirmation__modal__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-60%, -56%);
    display: flex;
    flex-direction: column;
    background-color: #fff;
    z-index: 99999999999;
    height: 200px;
}

.contract__termination__confirmation__header {
    display: flex;
    flex-direction: row;
    padding: 0 20px;
    justify-content: space-between;
    border-bottom: 1px solid grey;
}

.contract__termination__confirmation__content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    border-bottom: 1px solid grey;
    margin-bottom: 10px;
}

.contract__termination__confirmation__modal__button {
    display: flex;
    flex-direction: row;
    gap: 10px;
     margin-left: 10px;
}

.contract__termination__confirmation__modal__button button {
    width: 70px;
    height: 24px;
    
}

.contract__termination__confirmation__confirm {
    background: black;
    color: white;
    border: none;
    outline: none;
}

.contract__termination__confirmation__cancel {
    background: white;
    color: black;
    border: 1px solid black;
}