.display-none {
  display: none !important;
}
.mr-2 {
  margin-right: 20px;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.add-evaluation_wrapper .add-evaluation-form-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
}
.add-evaluation_wrapper .add-evaluation-form {
  display: flex;
  flex-wrap: wrap;
  gap: 60px;
  justify-content: start;
}
.add-evaluation_wrapper .add-evaluation-form .col-md-3,
.result-selection-wrapper .col-md-3,
.benchmark_data_wrapper .benchmark-data-ip-wrapper {
  width: calc(25% - 60px);
  max-width: calc(25% - 60px);
  position: relative;
}
.add-evaluation_wrapper .add-evaluation-form .col-md-3 span {
  display: block;
}
.add-evaluation_wrapper .add-evaluation-form .col-md-3 .avf-label,
.result-selection-wrapper .col-md-3 .avf-label,
.benchmark_data_wrapper .benchmark-data-ip-wrapper .avf-label,
.benchmark_data_wrapper .file-upload-label {
  font-size: 14px;
  font-weight: bold;
  min-height: 1.25rem;
  display: block;
}
.result-selection-wrapper {
  display: flex;
  gap: 15px;
  padding: 20px 35px;
}
.add-evaluation_wrapper .add-evaluation-form .col-md-3 select,
.add-evaluation_wrapper .add-evaluation-form .col-md-3 input,
.result-selection-wrapper .col-md-3 select,
.result-selection-wrapper .col-md-3 input,
.benchmark_data_wrapper .benchmark-data-ip-wrapper input,
.benchmark_data_wrapper .benchmark-data-ip-wrapper select {
  padding: 10px !important;
  display: block !important;
  width: 100% !important;
  height: 45px !important;
  max-width: 17vw !important;
  font-size: 1rem !important;
  line-height: 20px !important;
  box-shadow: none !important;
  background-color: #eaeaf2 !important;
  border-width: 0 0 1px 0 !important;
  border-style: none none solid none !important;
  border-radius: unset !important;
  border-bottom-color: #1a1a24 !important;
  color: #1a1a24;
  box-sizing: border-box !important;
  /* -moz-appearance: none; 
  -webkit-appearance: none; 
  appearance: none; */
}
.add-evaluation_wrapper .add-evaluation-form .col-md-3 select:hover,
.add-evaluation_wrapper .add-evaluation-form .col-md-3 input:hover,
.add-evaluation_wrapper .add-evaluation-form .col-md-3 select:focus,
.add-evaluation_wrapper .add-evaluation-form .col-md-3 input:focus,
.result-selection-wrapper .col-md-3 select:hover,
.result-selection-wrapper .col-md-3 input:hover,
.result-selection-wrapper .col-md-3 select:focus,
.result-selection-wrapper .col-md-3 input:focus,
.benchmark_data_wrapper .benchmark-data-ip-wrapper select:hover,
.benchmark_data_wrapper .benchmark-data-ip-wrapper input:hover,
.benchmark_data_wrapper .benchmark-data-ip-wrapper select:focus,
.benchmark_data_wrapper .benchmark-data-ip-wrapper input:focus {
  color: #fff !important;
  border-bottom-color: #ffe600 !important;
  border-style: none none solid none !important;
  border-width: 0 0 4px 0 !important;
  background-color: #1a1a24 !important;
  outline: none !important;
}

.add-evaluation_wrapper .add-evaluation-form .col-md-3 select:hover + .cppa-custom-tooltip,
.add-evaluation_wrapper .add-evaluation-form .col-md-3 input:hover + .cppa-custom-tooltip,
.add-evaluation_wrapper .add-evaluation-form .col-md-3 .p-calendar:hover + .cppa-custom-tooltip,
.result-selection-wrapper .add-evaluation-form .col-md-3 select:hover + .cppa-custom-tooltip,
.result-selection-wrapper .add-evaluation-form .col-md-3 input:hover + .cppa-custom-tooltip,
.result-selection-wrapper .add-evaluation-form .col-md-3 .p-calendar:hover + .cppa-custom-tooltip,
.benchmark_data_wrapper .add-evaluation-form .col-md-3 select:hover + .cppa-custom-tooltip,
.benchmark_data_wrapper .add-evaluation-form .col-md-3 input:hover + .cppa-custom-tooltip,
.benchmark_data_wrapper .add-evaluation-form .col-md-3 .p-calendar:hover + .cppa-custom-tooltip {
  visibility: visible;
}
.cppa-custom-tooltip, .datepicker-tooltip {
  visibility: hidden;
  max-width: 15vw;
  background-color: #3a3a4a;
  color: #fff;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  opacity: 0.9;
  top: 47px;
}
.datepicker-tooltip {
  top: 4.5rem;
}
.cppa-custom-tooltip::before, .datepicker-tooltip::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  top: -4px;
  left: 15px;
  background: #3a3a4a;
  transform: rotate(45deg);
}
.add-evaluation_wrapper .add-evaluation-form .col-md-3 .input-wrapper {
  position: relative;
}
.add-evaluation_wrapper .add-evaluation-form .col-md-3 select.avf-upload-btn,
.add-evaluation_wrapper .add-evaluation-form .col-md-3 input.avf-upload-btn {
  background-color: #1a1a24 !important;
  color: #fff !important;
}
.add-evaluation_wrapper .add-evaluation-form .error,
.result-selection-wrapper .error,
.benchmark_data_wrapper .error {
  margin: 0;
  padding: 0;
  color: var(--error-red);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: EYInterstate;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;

  /* color: #f95d54 !important;
  font-size: 12px !important;
  margin: 0 !important; */
}
.add-evaluation_wrapper .btn-primary,
.add-evaluation_wrapper .btn-secondary,
.ppa__result__options .btn-primary,
.ppa__result__options .btn-secondary {
  min-width: 115px;
  width: auto;
  border: 2px solid #1a1a24;
  height: 35px;
  line-height: 20px;
  font-size: 14px;
  padding: 5px 20px;
  font-weight: 600;
  cursor: pointer;
}
.add-evaluation_wrapper .btn-primary,
.ppa__result__options .btn-primary {
  background: #ffe600 !important;
  color: #1a1a24 !important;
}
.add-evaluation_wrapper .btn-secondary,
.ppa__result__options .btn-secondary {
  background: #1a1a24 !important;
  color: #ffffff !important;
}
.add-evaluation_wrapper .btn-primary[disabled],
.add-evaluation_wrapper .btn-secondary[disabled],
.ppa__result__options .btn-primary[disabled],
.ppa__result__options .btn-secondary[disabled] {
  background: #eaeaf2 !important;
  color: #747480 !important;
  cursor: not-allowed;
}
.add-evaluation_wrapper .ppa-actions {
  margin: 60px 0;
  text-align: right;
  justify-content: end;
}

.add-evaluation_wrapper .ppa-btn-wrapper {
  margin: 60px 0 20px 0;
  text-align: right;
  justify-content: end;
}

.ppa_result_wrapper {
  display: flex;
  gap: 20px;
  margin: 15px 35px;
}
.ppa_result_wrapper .ppa_result_table_container {
  min-width: 28%;
}
.ppa_result_wrapper .ppa_result_graphs_container {
  width: auto;
  flex: 1;
}
.ppa_result_wrapper .ppa_result_graphs_container .results_graphs .p-chart canvas {
  height: 30vh !important;
  min-height: 25vh !important;
  max-height: 25vh !important;
}
.ppa_result_wrapper .ppa_result_table_container table {
  width: 100% !important;
  margin-bottom: 20px;
}
.ppa_result_wrapper .ppa_result_table_container table thead th {
  background-color: #1a1a24;
  color: #ffffff;
  padding: 15px;
  font-size: 14px;
  font-weight: 100;
  font-family: EYInterstate;
}
.ppa_result_wrapper .ppa_result_table_container table tbody td {
  background-color: #ffffff;
  color: #2e2e3c;
  padding: 15px;
  font-size: 14px;
  font-weight: 100;
  font-family: EYInterstate;
  border-color: #c4c4cd;
  width: 50%;
  min-width: 50%;
  max-width: 50%;
}
.ppa_result_wrapper .ppa_result_table_container table tbody tr > td:first-of-type {
  font-weight: 400;
}
.ppa_result_wrapper .ppa_result_graphs_container .results_graphs .graph-heading {
  background-color: #fafafc;
  padding: 15px;
}
.ppa_result_wrapper .ppa_result_graphs_container .results_graphs .graph-heading h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
}
.ppa_result_wrapper .ppa_result_graphs_container .results_graphs .card {
  border: 1px solid #eaeaf2;
  margin-bottom: 15px;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}
.ppa_result_container .ppa__result__options {
  margin: 20px 35px;
  display: flex;
  justify-content: flex-end;
}

/* .add-evaluation_wrapper button, */
.uload_download_block button,
.ppa__result__options button {
  background: #1a1a24 !important;
  color: #ffffff !important;
  min-width: 130px;
  width: auto;
  border: 2px solid #1a1a24;
  height: 35px;
  padding: 5px 15px;
  font-weight: 600;
}
@media only screen and (max-width: 1480px) {
  .add-evaluation_wrapper button,
  .ppa__result__options button {
    margin-right: 15px;
  }
}
.add-evaluation_wrapper button span,
.ppa__result__options button span {
  line-height: 21px !important;
  font-size: 14px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  color: #ffffff !important;
  cursor: pointer;
}
.add-evaluation_wrapper .download_link,
.ppa__result__options .download_link {
  text-decoration: underline;
  font-size: 14px;
  font-style: italic;
  cursor: pointer;
}

@media only screen and (max-width: 1480px) {
  .add-evaluation_wrapper .download_link,
  .ppa__result__options .download_link {
    text-align: center;
  }
}
/* .add-evaluation_wrapper .upload-file-name,
.ppa__result__options .upload-file-name {
  font-size: 14px;
  font-weight: bold;
  display: block !important;
  line-height: 16px;
  overflow: hidden;
  word-wrap: break-word;
  word-break: break-word;
} */
.uload_download_block {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
  max-width: 17vw !important;
  margin-top: 0.5rem;
  position: relative;
}
@media only screen and (max-width: 1480px) {
  .uload_download_block {
    align-items: flex-start;
  }
}
.add-evaluation_wrapper.ppa_parameter .ppa_param_up_dn_wrapper {
  margin-bottom: 30px !important;
}
.add-evaluation_wrapper.ppa_parameter .ppa_parameter_upload_block {
  display: flex;
  gap: 60px;
  width: 100% !important;
  min-width: 100%;
}
.add-evaluation_wrapper.ppa_parameter .add-evaluation-form.ppa_parameter_form {
  flex-direction: column !important;
  gap: 0 !important;
}
.add-evaluation_wrapper.ppa_parameter .ppa_parameter_upload_block .ppa_param_up_dn_wrapper {
  max-height: 135px !important;
  height: auto !important;
  width: calc(32% - 60px);
}
.add-evaluation_wrapper,
.ppa_result_container {
  margin-bottom: 150px !important;
}

.benchmark-title {
  height: 40px;
  background: #ffe600;
  color: #1a1a24;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 26px;
  padding-left: 15px;
}

.benchmark-form-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  border: 1px solid #e1e1e6;
  padding: 10px 20px;
}

.benchmark_data_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  padding: 20px 20px 30px 20px;
  flex-direction: column;
}
.benchmark_data_wrapper .benchmark_data_upload_block {
  gap: 30px;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}
.benchmark_data_wrapper .benchmark-data-ip-wrapper {
  /* width: 100%; */
  /* gap: 30px; */
}
.benchmark_data_wrapper .benchmark_data_upload_block .file-upload-container {
  margin: 0;
  padding: 0;
}
.benchmark_data_wrapper .benchmark_data_upload_block .file-upload-label {
  margin: 0;
}
.benchmark_data_wrapper .benchmark_data_upload_block .active-invoice-container {
  margin: 0;
  height: auto;
}
.benchmark_data_wrapper .benchmark_data_upload_block .active-invoice-container .invoice-meta-container {
  margin: 16px;
  padding: 0;
}
.benchmark_data_wrapper .benchmark_data_upload_block .active-invoice-container .invoice-close-icon {
  cursor: pointer;
}
.benchmark_data_wrapper .file-upload-actions {
  margin: 0;
  width: 100%;
  align-items: center;
  justify-content: end;
}
.benchmark_data_wrapper .success__modal__container {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.benchmark_data_wrapper .success__modal__container .success__modal__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid #c4c4cd !important;
  margin: 0;
  padding: 20px;
}
.benchmark_data_wrapper .success__modal__container .success__modal__header p {
  padding: 0;
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  font-family: EYInterstate;
  color: #1a1a24;
}
.benchmark_data_wrapper .success__modal__container .success__modal__header p:last-of-type {
  cursor: pointer;
}
.benchmark_data_wrapper .success__modal__container .success__modal__content {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 0.5px solid #c4c4cd !important;
  padding: 25px 20px;
}
.benchmark_data_wrapper .success__modal__container .success__modal__content p {
  padding: 0;
  margin: 0;
  font-size: 14px;
  line-height: 22px;
  font-weight: 100;
  font-family: EYInterstate;
  color: #1a1a24;
  text-align: center;
}
.benchmark_data_wrapper .success__modal__container .success__modal__footer {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 20px;
  padding: 20px;
}
.benchmark_data_wrapper .success__modal__container .success__modal__footer .button__finish {
  min-width: 115px;
  height: 45px !important;
  width: auto;
  border: 2px solid #1a1a24;
  background: #1a1a24;
  color: #ffffff;
  height: 35px;
  line-height: 30px;
  font-size: 14px;
  padding: 5px 20px;
  font-weight: 600;
  cursor: pointer;
  margin: 0;
}
.error-msg-container {
  align-items: center;
  justify-content: flex-start;
  gap: 3px;
}
.error-msg-container .error-icon {
  height: 20px;
  width: 20px;
}
.error-msg-container .error {
  line-height: normal;
  padding-top: 2px;
}
.download_link.expanded-download-btn {
  display: flex !important;
  align-items: center;
  gap: 5px;
  text-decoration: none !important;
  border: 1px solid #1a1a24;
  width: fit-content;
  padding: 8px;
  height: 35px;
  /* max-width: 130px; */
  color: #1a1a24;
  cursor: pointer;
}
.download_link.collapsed-download-btn {
  height: 35px;
  box-sizing: border-box;
  border: 1px solid #1a1a24;
  padding: 5px;
  align-items: center;
  justify-content: center;
  position: relative;
}
.download_link.collapsed-download-btn > .cppa-custom-tooltip.tooltip-dn-btn {
  top: 40px;
  left: -5px;
  min-width: 10vw;
  max-width: 10vw;
  font-style: normal !important;
}
.download_link.collapsed-download-btn:hover > .cppa-custom-tooltip.tooltip-dn-btn {
  visibility: visible;
}
.download_link img {
  height: 20px;
  width: 20px;
}
.download_link a {
  text-decoration: none !important;
  font-style: normal;
}
.upload-readonly,
.upload-readonly + .file-upload-actions {
  opacity: 0.2;
  pointer-events: none;
  cursor: not-allowed !important;
}
.uload_download_block > .file-container {
  flex: 1;
}
.uload_download_block > .file-container .file-path {
  margin: 0;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 8px;
  height: 100%;
  min-height: 45px;
  background-color: #eaeaf2 !important;
  border-width: 0 0 1px 0 !important;
  border-style: none none solid none !important;
  border-radius: unset !important;
  border-bottom-color: #1a1a24 !important;
  color: #1a1a24;
  padding: 5px;
}
.uload_download_block > .file-container .file-path .file-icon,
.uload_download_block > .file-container .file-path .close-icon {
  height: 16px;
  width: 16px;
  margin: auto 0;
}
.uload_download_block > .file-container .file-path .close-icon {
  cursor: pointer;
}
.uload_download_block > .file-container .file-path .upload-file-name {
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  overflow: hidden;
  word-wrap: break-word;
  word-break: break-all;
  flex: 1;
  max-height: 32px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.uload_download_block > .file-container + .display-none + .download_link.collapsed-download-btn {
  display: none;
}

.uload_download_block > button + .display-none + .download_link.collapsed-download-btn {
  display: flex !important;
}

.p-calendar.p-invalid.p-component > .p-inputtext {
  border-color: #fca5a5;
}

.p-datepicker {
  padding: 0.5rem;
  background: #071426;
  color: rgba(255, 255, 255, 0.87);
  border: 1px solid #0b213f;
  border-radius: 6px;
}
.p-datepicker:not(.p-datepicker-inline) {
  background: #ffffff;
  color: #1a1a24;
  border: 1px solid #1a1a24;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
}
.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
  background: #1a1a24;
  border: none;
  border-radius: 0;
}
.p-datepicker .p-datepicker-header {
  padding: 0.5rem;
  color: #ffffff;
  background: #071426;
  font-weight: 700;
  margin: 0;
  border-bottom: 1px solid #0b213f;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  width: 2rem;
  height: 2rem;
  color: #ffffff;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
  color: rgba(255, 255, 255, 0.87);
  border-color: transparent;
  background: rgba(255, 255, 255, 0.03);
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-datepicker .p-datepicker-header .p-datepicker-title {
  line-height: 2rem;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  color: #fff;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  font-weight: 700;
  padding: 0.5rem;
  background: unset;
  box-shadow: none;
  border: none;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  margin-right: 0.5rem;
}
.p-datepicker table {
  font-size: 1rem;
  margin: 0.5rem 0;
}
.p-datepicker table th {
  padding: 0.5rem;
}
.p-datepicker table th > span {
  width: 2.5rem;
  height: 2.5rem;
}
.p-datepicker table td {
  padding: 0.5rem;
}
.p-datepicker table td > span {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  transition: box-shadow 0.2s;
  border: 1px solid transparent;
}
.p-datepicker table td > span.p-highlight {
  color: rgba(255, 255, 255, 0.87);
  background: rgba(165, 180, 252, 0.16);
}
.p-datepicker table td > span:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(165, 180, 252, 0.5);
}
.p-datepicker table td.p-datepicker-today > span {
  background: transparent;
  color: #a5b4fc;
  border-color: transparent;
}
.p-datepicker table td.p-datepicker-today > span.p-highlight {
  color: rgba(255, 255, 255, 0.87);
  background: rgba(165, 180, 252, 0.16);
}
.p-datepicker .p-datepicker-buttonbar {
  padding: 1rem 0;
  border-top: 1px solid #0b213f;
}
.p-datepicker .p-datepicker-buttonbar .p-button {
  width: auto;
}
.p-datepicker .p-timepicker {
  border-top: 1px solid #0b213f;
  padding: 0.5rem;
}
.p-datepicker .p-timepicker button {
  width: 2rem;
  height: 2rem;
  color: rgba(255, 255, 255, 0.6);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-datepicker .p-timepicker button:enabled:hover {
  color: rgba(255, 255, 255, 0.87);
  border-color: transparent;
  background: rgba(255, 255, 255, 0.03);
}
.p-datepicker .p-timepicker button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(165, 180, 252, 0.5);
}
.p-datepicker .p-timepicker button:last-child {
  margin-top: 0.2em;
}
.p-datepicker .p-timepicker span {
  font-size: 1.25rem;
}
.p-datepicker .p-timepicker > div {
  padding: 0 0.5rem;
}
.p-datepicker.p-datepicker-timeonly .p-timepicker {
  border-top: 0 none;
}
.p-datepicker .p-monthpicker {
  margin: 20px 10px 0 10px;
}
.p-datepicker .p-monthpicker .p-monthpicker-month {
  padding: 0.5rem;
  transition: box-shadow 0.2s;
  border-radius: 6px;
  margin-bottom: 20px;
}
.p-datepicker .p-monthpicker .p-monthpicker-month:hover,
.p-datepicker .p-monthpicker .p-monthpicker-month:focus {
  background: #e1e1e6 !important;
  border-radius: 0;
}
.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
  color: #ffffff !important;
  background: #1a1a24 !important;
  border-radius: 0;
  border: none;
}
.p-datepicker .p-monthpicker .p-monthpicker-month:hover,
.p-datepicker .p-monthpicker .p-monthpicker-month:focus,
.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight:hover,
.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight:focus {
  outline: none !important;
  box-shadow: none !important;
}
.p-datepicker .p-yearpicker {
  margin: 20px 10px 0 10px;
}
.p-datepicker .p-yearpicker .p-yearpicker-year {
  padding: 0.5rem;
  transition: box-shadow 0.2s;
  border-radius: 6px;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year.p-link {
  cursor: pointer;
}
.p-datepicker .p-yearpicker .p-yearpicker-year:hover,
.p-datepicker .p-yearpicker .p-yearpicker-year:focus {
  background: #eaeaf2 !important;
  color: #1a1a24 !important;
  border-radius: 0;
}
.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
  color: #ffffff !important;
  background: #1a1a24 !important;
  border-radius: 0;
  border: none;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
  border-left: 1px solid #0b213f;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-top: 0;
  padding-bottom: 0;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
  padding-left: 0;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
  padding-right: 0;
  border-left: 0 none;
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
  background: rgba(255, 255, 255, 0.03);
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(165, 180, 252, 0.5);
}
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover {
  background: rgba(255, 255, 255, 0.03);
}
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(165, 180, 252, 0.5);
}
.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
  background: rgba(255, 255, 255, 0.03);
}
.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(165, 180, 252, 0.5);
}

@media screen and (max-width: 769px) {
  .p-datepicker table th,
  .p-datepicker table td {
    padding: 0;
  }
}
.ppa-info-container-right {
  margin: 0;
  text-align: right;
  font-size: 10px;
  font-family: EYInterstate;
}

/* datepicker styles from primereact css file  */

.p-calendar {
  position: relative;
  display: inline-flex;
  max-width: 100%;
}

.p-calendar .p-inputtext {
  flex: 1 1 auto;
  width: 1%;
}

.p-calendar-w-btn-right .p-inputtext {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.p-calendar-w-btn-right .p-datepicker-trigger {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.p-calendar-w-btn-left .p-inputtext {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.p-calendar-w-btn-left .p-datepicker-trigger {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* Fluid */
.p-fluid .p-calendar {
  display: flex;
}

.p-fluid .p-calendar .p-inputtext {
  width: 1%;
}

/* Datepicker */
.p-calendar .p-datepicker {
  min-width: 100%;
}

.p-datepicker {
  width: auto;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #747480;
}

.p-datepicker-inline {
  display: inline-block;
  position: static;
  overflow-x: auto;
}

/* Header */
.p-datepicker-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.p-datepicker-header .p-datepicker-title {
  margin: 0 auto;
}

.p-datepicker-prev,
.p-datepicker-next {
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

/* Multiple Month DatePicker */
.p-datepicker-multiple-month .p-datepicker-group-container {
  display: flex;
}

.p-datepicker-multiple-month .p-datepicker-group-container .p-datepicker-group {
  flex: 1 1 auto;
}

/* Multiple Month DatePicker */
.p-datepicker-multiple-month .p-datepicker-group-container {
  display: flex;
}

/* DatePicker Table */
.p-datepicker table {
  width: 100%;
  border-collapse: collapse;
}

.p-datepicker td > span {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

/* Month Picker */
.p-monthpicker-month {
  width: 26%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

/*  Button Bar */
.p-datepicker-buttonbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Time Picker */
.p-timepicker {
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-timepicker button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.p-timepicker > div {
  display: flex;
  align-items: center;
  flex-direction: column;
}

/* Touch UI */
.p-datepicker-touch-ui,
.p-calendar .p-datepicker-touch-ui {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 80vw;
  transform: translate(-50%, -50%);
}

/* Year Picker */
.p-yearpicker-year {
  width: 26%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
}

.col-md-3 .options__icons {
  position: absolute;
  top: 25px;
}

.add-evaluation-form .col-md-3 label {
  font-size: 14px;
  font-weight: bold;
  min-height: 1.25rem;
}