.add-energy-form-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.add-energy-form {
    display: flex;
    flex-wrap: wrap;
}

.add-energy-form-group {
    width: calc(33.33% - 10px);
    height:115px;
    margin: 5px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
}


.add-energy-date-selector {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 5px;
    height: 90px;
}

.add-energy-date-selector .options__icons {
    top:25px
}
.add-energy-form-group label {
    color: var(--primary-grey);
    font-size: 14px;
    font-weight: 700;
    font-family: EYInterstate;
}

.add-energy-input {
    border: none;
    background-color: var(--placeholder-background);
    border-bottom: 1px solid black;
    height: 30px;
    padding: 8px;
}

.add-energy-select-input {
    height: 45px;
}

.add-energy-input::placeholder {
    color: black !important;
}


.add-energy-form-group .cal__icon {
    position: absolute;
    right: 8px;
    top: 38px;
}
.add-energy-form-container .add-energy-actions {
    margin-left: 15px;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.add-energy-actions button {
    border: none;
    font-weight: bold;
    font-size: 12px;
    height: var(--button-height);
    padding: 11px 36px;
    border: 1px solid black;
    cursor: pointer;
}

.add-energy-actions .confirm-button {
    background-color: #FFE600;
    color: black;
}

.add-energy-actions .confirm-button:disabled{
    color: #747480;
    background-color: #EAEAF2;
}

.cancel-button {
    background-color: white;
}