:root {
  --primary-color: #007bff;
  --light-grey: #2E2E38;
  --primary-grey: #2E2E38;
  --primary-yellow: #FFE600;
  --primary-white: #FFFFFF;
  --alternate-grey: #3A3A4A;
  --primary-black: #1A1A24;
  --placeholder-background:  #F6F6FA;
  --disabled-background-color: #EAEAF2;
  --disabled-color: #747480;
  --error-red:#B9251C;
  --shadow-grey:#C4C4CD;
  --sidenav-light-grey:#23232F;
  --input-light-gray:#ededed;
  --textbox-height:46px;
  --button-height:44px;
  --toast-success-hue: 150;
  --toast-failure-hue: 20;
  --toast-warning-hue: 205;
  --toast-margin: 0.5em;
  --toast-bg-color-fallback: hsl(var(--secondary-hue) 15% 100%);
  --toast-text-color-fallback: hsl(var(--secondary-hue) 10% 25%);
  --toast-opacity: 0.99;
  --toast-content-gap: 5px;
  --toast-speed: 250ms;
  --toast-list-width: 400px;
  --toast-list-padding: 1em;
}

@font-face {
  font-family: 'EYInterstate';
  src: local('EYInterstate'), url(../public/fonts/EYInterstate-Regular.ttf) format('truetype');
}

body {
  margin: 0;
  padding: 0;
  font-family: EYInterstate;
}

ul,li {
  list-style: none;
}

a {
  text-decoration: none;
  color: black;
}

input{
  height: 46px;
  color: black !important;
  font-family: EYInterstate;
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0px;
}
select {
  cursor: pointer;
  color: black !important;
  font-family: EYInterstate;
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0px;
}
iframe{
  border: none;
}




