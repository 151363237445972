.add-user_wrapper {
    margin-top: 20px;
}

.add-user-title {
    height: 40px;
    background: var(--primary-yellow);
    color: var(--primary-black);
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 20px;
    margin-right: 20px;
    padding-right: 26px;
    padding-left: 15px;
}

.add-user-form-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-left: 20px;
    margin-right: 20px;
    border: 1px solid #E1E1E6;
    padding: 10px 20px;
    
}

.add-user-actions {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 20px;
}

.add-user-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.add-user-form-group {
    margin-top: 1rem;
    width: 45%;
    height: 95px;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 10px;
}

.add-user-form-group label {
    color: var(--primary-grey);
    font-size: 14px;
    font-weight: 700;
    font-family: EYInterstate;
}

.add-user-form-group select{
    height: 45px;
}

.cal__icon {
    position: absolute;
    right: 0;
    top:20px;
}

.add-user-input {
    border: none;
    background-color: var(--placeholder-background);
    border-bottom: 1px solid black;
    height: 30px;
    padding: 8px;
}
.add-user-input:disabled {
    cursor: not-allowed;
}

.add-user-select-input {
    height: 45px;
}

.add-user-input::placeholder {
    color: black !important;
}

.add-user-actions button {
    border: 1px solid black;
    font-weight: bold;
    font-size: 12px;
    height: var(--button-height);
    padding: 11px 36px;
    cursor: pointer;
}

.add-user-actions .confirm-button {
    background-color: var(--primary-yellow);
    color: black;
}

.add-user-actions .confirm-button:disabled{
    color: var(--disabled-color);
    background-color: var(--disabled-background-color);
    cursor: not-allowed;
}

.cancel-button {
    background-color: white;
}

.info-container {
    font-family: EYInterstate;
    font-weight: 300;
    font-size: 10px;
    color:  var(--primary-grey);
    margin-left: 20px;
    margin-top: 14px;
}