.table {
    width: 100%;
    margin-left: 0;
    border-collapse: collapse;
    margin-top: 2rem;
    overflow-x: auto;
}

.tab__search .table-search-box {
    height: var(--textbox-height);
    width:337px;
    background-color: var(--placeholder-background);
}

.tab__search .search-icon {
    position: absolute;
    top: 10px;
    left: 310px;
    width: 30px;
    height: 30px;
}

.table .header {
    position: relative;
    display: flex;
    align-items: center;
}

.table td, .table th {
    text-align: left;
    border-bottom: 1px solid #ccc;
    height: 8px;

    color:var(--light-grey);
    font-family: EYInterstate;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px; /* 166.667% */
}

.table th {
    font-weight: 700;
}


.table thead th {
    background-color: #f0f0f0;
}

.table thead {
    height: 44px;
}

.column-header-container {
    display: flex;
    align-items: center;
    padding-left: 0.5rem;
}

.column-header {
    color: var(--light-grey);
    font-family: EYInterstate;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
}

.column-header-icon {
    cursor: pointer;
}

.fa-icon{
    padding: 0px 8px;
    cursor: pointer;
    width: 0.75rem;
    height: 0.75rem;
}

.column-data {
    color: var(--light-grey);
    font-family: EYInterstate;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    padding-left: 0.5rem;
    max-width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.table-norecords{
    display: flex;
    justify-content: center;
    font-family: EYInterstate;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
}

.table-footer {
    width: calc(100% - 15px);
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 10px;
}
