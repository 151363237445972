.generator__contract__details__wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 65vh;
}

.generator-contract-tabs{
    margin-left: 10px;
    height: 80%;
    width: 100%;
}

.backbutton {
    margin-top: 20px;
    margin-left: 10px;
}

.backbutton button{
    display: inline-flex;
    height: 36px;
    padding: 7px 30px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    cursor: pointer;
}

.contract__module__title {
    font-size: 10px;
}