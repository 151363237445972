.footer__container {
    padding-top: 5px;
    margin-top: auto;
    flex-shrink: 0;
    display: flex;
    bottom: 0;
    flex-direction: row;
    gap: 10px;
    background: var(--disabled-color);
    box-shadow: 0px -2px 8px 0px var(--shadow-grey);
}

.footer_content_left {
    align-items: flex-start;
    width: 75%;
    flex-direction: column;
    padding-left: 5px;
}

.footer__logo {
    padding-left: 5px;
}

.footer_content_left  p, .footer__container p {
    color: #FFF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: EYInterstate;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
    padding-left: 5px 
}

.footer_content_right {
    display: flex;
    width: 25%;
    flex-direction: row;
    gap:10px;
    margin-right: 1rem;
}

.footer__options {
    width: 100%;
    display: flex;
    gap: 10px;
}

.footer_content_right  p {
    color:#FFF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: EYInterstate;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    cursor: pointer;
}
