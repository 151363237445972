.powerbi-dashboard-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
.powerbi-dashboard-tabs {
    height: 100%;
    width: 100%;
}
.powerBI_report{
    width: 100%;
    height: 100%;
}
.PowerBi-Container{
    overflow: hidden;
    height: 100vh;
}