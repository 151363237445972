.react-calendar {
    position: absolute;
    z-index: 9999 !important;
    width: 250px;
    top: 50px;
}

.react-calendar__navigation {
    background-color: black;
    margin-bottom: 0px;
    color: white;
}
.react-calendar__navigation__arrow {
    color: white;
}

.react-calendar__navigation__label {
    color: white;
}

.react-calendar__month-view__weekdays {
    background-color: black;
    color: white;
}
.react-calendar__navigation button:disabled{
    background-color: #000;
}