.admin__client__onboarding__wrapper {
    margin-top: 20px;
    /* border: 1px solid grey; */
}

.admin__client__onboarding__form__container {
    display: flex;
    border: 1px solid grey;
    /* flex-direction: column;
    gap: 15px; */
    margin-left: 20px;
    margin-right: 20px;
    /* border: 1px solid grey; */
    padding: 10px 20px;
    border-bottom: none;
}

.admin__client__title {
    height: 40px;
    background: var(--primary-yellow);
    color: black;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 20px;
    margin-right: 20px;
    padding-right: 26px;
    padding-left: 15px;
}

/* .ppa__client__options {
        display: flex;
        flex-direction: row;
        gap: 20px;
        justify-content: flex-start;
        margin-left: 20px;
        padding-left: 18px;
        /* margin-top: 10px; */
        /* border: 1px solid grey;
        width: 95.0%;
        border-top: none;
        padding-bottom: 10px; */
/* } */ 

.ppa__client__options {
    display: flex;
    flex-direction: row;
    padding: 10px 20px;
	margin-left: 20px;
    margin-right: 20px;
    border: 1px solid grey;
    border-top: none;
    gap: 10px;
}

.admin__client__onboarding__form {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
    /* flex-wrap: wrap;
    justify-content: space-between; */
}
.admin__client__onboarding__form .input_row {
    display: flex;
    flex-wrap: wrap;
}

.input_row_two_column{
    display: flex;
    flex-wrap: wrap;
    width: 66.7%;
}

.admin__client__form__group {
    height: 95px;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 10px;
    flex: 31.5%;
    margin-right: 15px;
}

.admin__client__form__group label{
    color: var(--primary-grey);
    font-size: 14px;
    font-weight: 700;
    font-family: EYInterstate;
}

.admin__client__form__group_two_Column{
    height: 95px;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 10px;
    margin-right: 15px;
    width: calc(50% - 15px);
}

.admin__client__form__group_two_Column label{
    color: var(--primary-grey);
    font-size: 14px;
    font-weight: 700;
    font-family: EYInterstate;
}



.LGC_Feild{
    margin-right: 35%;
}

.cal__icon {
    position: absolute;
    right: 0;
    top:20px;
}

.admin__client__input {
    border: none;
    background-color: var(--placeholder-background);
    border-bottom: 1px solid black;
    box-sizing: border-box;
    height: var(--textbox-height);
    padding: 8px;
}

.admin_input_error{
    margin: 0;
    padding: 0;
    color: var(--error-red);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: EYInterstate;
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
}

.admin__client__input::placeholder {
    color: black !important;
}

.ppa__client__options button {
    border: 1px solid black;
    font-weight: bold;
    font-size: 12px;
    height: var(--button-height);
    padding: 11px 36px;
    cursor: pointer;
}

.forward__option {
    background-color: var(--primary-yellow);
    color: black;
}

.forward__option:disabled{
    color: var(--disabled-color);
    background-color: var(--disabled-background-color);
    cursor: not-allowed;
}

.cancel__option {
    background-color: white;
}