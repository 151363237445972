.toast-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 20rem;
    color: var(--primary-grey);
    font-family: EYInterstate;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
}

.toast-container button {
    border: none;
    background: none;
    cursor: pointer;
}