.generator__banner__search__component {
    height: 180px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 25px;
    background-image: url('/public/generator_banner.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.generator__contract__table__view {
    margin: 1rem 3rem;
}

.view-contract-icon {
    cursor: pointer;
}